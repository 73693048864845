<script>
  import { _ } from 'svelte-i18n'
  let langs = [
    ["en", "English"],["de", "German"],["es","Español"],["fr","Français"],["it","Italiano"],["jp","日本"],["nl","Dutch"],["tr","Turkish"]
  ]
	import { locale } from 'svelte-i18n';
</script>

<style>
  @media only screen and (max-width: 610px) {
    img {
      display: none;
    }
    .title {
      font-size: 1em !important;
    }
    .nav {
      font-size: 1em !important;
    }
    .lang p {
      display: none;
    }
    select {
      font-size: 1em;
    }
  }
  .nav {
  background-color:black;
  color: white;
  height:10vh;
  display: flex;
  margin:0;
  padding: 0;
  align-content: flex-end;
  justify-content: space-between;
}
.title {
  display: flex;
  align-items: center;
  padding-left: 2em;
  font-size:1.5em;
  font-weight: 600;
}

.lang {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 2em;
  font-size:1em;
  font-weight: 200;
}
select {
  margin-left: 1em;
  margin-bottom: 0;
}
img {
  height: 40%;
  margin-right:2em;
}
.logo {
  height:100%;
  display: flex;
}
</style>

<div class="nav">
  <div class="title">{$_('title', { default: 'Product Downloads' })}</div>
  <div class="lang"><p>{$_('lang', { default: 'Language' })}:</p>
    <select bind:value={$locale}>
      {#each langs as lang}
      <option value={lang[0]}>{lang[1]}</option>
      {/each}
    </select>
  </div>
</div>
