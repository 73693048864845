<script>
	import { _, addMessages, init, getLocaleFromNavigator } from 'svelte-i18n';
	import { locale } from 'svelte-i18n';
	import en from './locales/en.json';
	import de from './locales/de.json';
	import es from './locales/es.json';
	import fr from './locales/fr.json';
	import it from './locales/it.json';
	import jp from './locales/jp.json';
	import nl from './locales/nl.json';
	import tr from './locales/tr.json';
	import Router from 'svelte-spa-router';

	addMessages('en', en);
	addMessages('de', de);
	addMessages('es', es);
	addMessages('fr', fr);
	addMessages('it', it);
	addMessages('jp', jp);
	addMessages('nl', nl);
	addMessages('tr', tr);
	let lang = getLocaleFromNavigator();
	if(lang.includes("-")){
		let res = lang.split("-")[0]
		lang = res;
	}
	init({
	  fallbackLocale: 'en',
	  initialLocale: lang,
	});
	locale.set(lang)

	import Nav from './component/Nav.svelte'
	import Content from './component/Content.svelte'
	import SideBar from './component/SideBar.svelte'
	import Docs from './component/Docs.svelte'
	import Footer from './component/Footer.svelte'
	import Home from './page/Home.svelte';
	import Documents from './page/Documents.svelte';
	const routes = {
		// Exact path
		'/': Home,
		'/list/:part': Documents,
	}
</script>
<svelte:head>
<title>{$_('title', { default: 'Product Downloads' })}</title>
</svelte:head>
<Nav />
<Content>
	<SideBar />
	<Docs>
		<Router {routes}/>
	</Docs>
</Content>
<Footer />
