<style>
  .foot {
  background-color:black;
  color: white;
  height:5vh;
  display: flex;
  margin:0;
  padding: 0;
}
.item {
  flex:1;
  padding-left:2em;
  line-height:5vh;
}
a {
  color: white;
}
</style>
<div class="foot">
  <div class="item">&copy; <a href="https://osukl.com">Oceanic Systems (UK) Ltd</a> 2022</div>
</div>
