<script>
  import { _ } from 'svelte-i18n'
import active from 'svelte-spa-router/active'
import { push } from 'svelte-spa-router'
import lines from '../config/parts.json'
let navOpen = false;
</script>

<style>
  @media only screen and (max-width: 610px) {
    .col-left {
      flex:10% !important;
      padding: 0 !important;
      padding: 1em !important;
      padding-top: 1em !important;
      border: none !important;
    }
    .col-left ul {
      display: none;
    }
    button {
      display: inline-block !important;
      width: 100%;
      border: none;
      background-color: #fff;
    }
  }
  button {
    display:none;
  }
  .col-left {
  padding: 2em;
  flex: 25%;
  border-right: 1px solid #ccc;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  padding: 1em 15px 1em 15px;
  margin-bottom: -1px;
  line-height: 1.5em;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
li:hover {
  background-color:#fff;
}
:global(li.active) {
  font-weight: 600;
  background-color:#e5e5e5;
}
.mobileSidebar {
  width:350px;
  height:100%;
  background-color: white;
  position: absolute;
  top: 0;
  left:0;
  margin:0;
  padding-top: 2em;
  padding-left: 0;
  padding-right:0;
}
</style>
{#if navOpen}
<div class="mobileSidebar">
  <button on:click={() => navOpen = !navOpen}>≡</button>
  <ul>
    {#each lines as item}
    <li on:click={() => {push('/list/'+item.id); navOpen=false}} use:active={{path: '/list/'+item.id, className: 'active', inactiveClassName: 'inactive'}}>{$_(item.title, { default: item.title })}</li>
    {/each}
  </ul>
</div>
{/if}
<div class="col-left">
  <button on:click={() => navOpen = !navOpen}>≡</button>
    <ul>
      {#each lines as item}
      <li on:click={() => push('/list/'+item.id)} use:active={{path: '/list/'+item.id, className: 'active', inactiveClassName: 'inactive'}}>{$_(item.title, { default: item.title })}</li>
      {/each}
    </ul>
  </div>
