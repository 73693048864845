<script>
  import { _ } from 'svelte-i18n'
  export let params;
  import lines from '../config/parts.json'
  	import { beforeUpdate, afterUpdate } from 'svelte';
  let result;
  beforeUpdate(() => {
  if(params.part){
    for (let i = 0; i < lines.length; i++){
      if(parseInt(lines[i].id) == parseInt(params.part)) result = lines[i];
    }
  }
});
</script>
<style>
.container {
  line-height: 1.6em;
  height: 100%;
  overflow-y: auto;
}
 .changes {
   padding-top: 0;
   padding-bottom: 0;
   padding-left:1em;
 }
 .changelog {
   padding-top: 0;
   padding-bottom: 0;
   margin: 1em 0 0 0;
 }
 </style>
 <div class="container">
<h2> {$_(result.title, { default: result.title })} <small>({result.part})</small></h2>
{#if result.datasheets}
<h3> {$_('doc.datasheet', { default: "Datasheets" })} </h3>
{#each result.datasheets as sheet}
{sheet.version} - {sheet.date} - <a href={sheet.uri}>{$_('doc.download', { default: "View / Download" })}</a>
{/each}
{/if}
{#if result.manuals}
<h3> {$_('doc.manuals', { default: "User Manuals" })} </h3>
{#each result.manuals as sheet}
{sheet.version} - {sheet.date} - <a href={sheet.uri}>{$_('doc.download', { default: "View / Download" })}</a>
<br />
{/each}
{/if}

{#if result.updates}
<h3> {$_('doc.updates', { default: "Software Updates" })} </h3>
{#each result.updates as sheet}
  {sheet.version} - {sheet.date} - <a href={sheet.uri}>{$_('doc.downloadonly', { default: "Download" })}</a>
  <div class="changes">
  <h4 class="changelog">{$_('doc.changelog', { default: "Changelog:" })}</h4>
  {#each sheet.changes as change}<p>{change}</p>{/each}
  </div>
  {/each}
  <div class="changes">
  <h4 class="changelog">{$_('doc.instructions', { default: "Instructions" })}</h4>
  <ul class="numbered">
    <li>{$_('doc.updatesStep.1')}</li>
    <li>{$_('doc.updatesStep.2')}</li>
    <li>{$_('doc.updatesStep.3')}</li>
    <li>{$_('doc.updatesStep.4')}</li>
    <li>{$_('doc.updatesStep.5')}</li>
    <li>{$_('doc.updatesStep.6')}</li>
  </ul>
  </div>
{/if}
</div>
